// Here you can add other styles
.custom-file-label {
    margin: 0px 15px 0px 15px !important;
}

.hidden {
    display: none;
}

.image-block {
    position: relative;
}

.image-block button {
    position: absolute;
    top: 10px;
    right: 25px;
}

.image-block img {
    width: 100%;
}

.btn-rmf {
    position: absolute;
    right: 11px;
    top: 9px;
}

.fa-border {
    border: none !important;
    width: 24px;
    text-align: center;
}

.react-tel-input .form-control {
    width: 100% !important;
}

.minWidth400 {
    min-width: 400px;
}

.methodbadge span {
    text-transform: uppercase;
    min-width: 50px;
}

.c-sidebar-nav i.fa {
    font-size: 18px;
    width: 40px;
    padding-left: 3px;
}

.word-break-div, .word-break-div * {
    word-wrap: break-word;
    white-space: pre-line;
}

shortcode {
    color: blue !important;
    border: 1px solid;
    padding: 5px 15px 5px 45px;
    position: relative;
    display: inline-block;
}

shortcode::before {
    position: absolute;
    content: "SC";
    display: block;
    background-color: blue;
    color: white;
    padding: 5px 5px;
    left: 0px;
    top: 0px;
    font-weight: bold;
}
.image{
    width: 300px;
    margin-top: 40px;
    margin-bottom: 10px;
}